import React from 'react';
import styles from './ProjectBox.module.scss';

export default function ProjectBox() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Website Redesign Project</div>
      <div className={styles.gauge} />
      <ProjectItem color="#85B867" backgroundColor="#F4FAF0">
        3 Action Items
      </ProjectItem>
      <ProjectItem color="#C35658" backgroundColor="#FCEDED">
        2 Issues
      </ProjectItem>
      <ProjectItem color="#CA993B" backgroundColor="#FFF9EE">
        3 Insights
      </ProjectItem>
    </div>
  );
}

function ProjectItem({ children, color, backgroundColor }) {
  return (
    <div className={styles.item} style={{ color, backgroundColor }}>
      {children}
    </div>
  );
}
